import { createStore } from 'zustand/vanilla';
export * from 'zustand/vanilla';
import { useDebugValue } from 'react';
import useSyncExternalStoreExports from 'use-sync-external-store/shim/with-selector.js';

const { useSyncExternalStoreWithSelector } = useSyncExternalStoreExports;
function useStore(api, selector = api.getState, equalityFn) {
  const slice = useSyncExternalStoreWithSelector(
    api.subscribe,
    api.getState,
    api.getServerState || api.getState,
    selector,
    equalityFn
  );
  useDebugValue(slice);
  return slice;
}
const createImpl = (createState) => {
  if ((import.meta.env && import.meta.env.MODE) !== "production" && typeof createState !== "function") {
    console.warn(
      '[DEPRECATED] Passing a vanilla store will be unsupported in the future version. Please use `import { useStore } from "zustand"` to use the vanilla store in React.'
    );
  }
  const api = typeof createState === "function" ? createStore(createState) : createState;
  const useBoundStore = (selector, equalityFn) => useStore(api, selector, equalityFn);
  Object.assign(useBoundStore, api);
  return useBoundStore;
};
const create = (createState) => createState ? createImpl(createState) : createImpl;
var react = (createState) => {
  if ((import.meta.env && import.meta.env.MODE) !== "production") {
    console.warn(
      "[DEPRECATED] default export is deprecated, instead import { create } from'zustand'"
    );
  }
  return create(createState);
};

export { create, react as default, useStore };
